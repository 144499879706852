<template>
		<div>
				<div v-if="blogs==null || blogs.count==0"
					class="full center"
				>
					<h1>This blog does not exist.</h1>
				</div>

				<div
					class="blog_cont"
				>
					<div 
						class="full center"
						v-if="isInvestor"
					>
						<div :style="[titleFontStyle]">
							Investor Updates
						</div>	
						<div 
							v-if="$root.investor.loggedIn==false"
						>
							Please log in to view investor information.
						</div>
					</div>
					<div 
						class="full center"
						v-if="isNews"
					>
						<div :style="[titleFontStyle]">
							Media Room
						</div>	
					</div>
					<div 
						class="full center"
						v-if="isBlog"
					>
						<div :style="[titleFontStyle]">
							Blog
						</div>
					</div>
					<div 
						v-if="isUpdater"
					>
						<a
							href="/investor/"
						>
								All Investor Updates 
						</a>
						<div v-if="$root.investor&&$root.investor.dfAuth==true&&$root.investor.loggedIn">
							<v-btn
								outlined
								v-on:click='newBlog($event)'
							>
								New Investor Update
							</v-btn>
							<br>
						</div>
					</div>
					<div 
						v-if="isReporter"
					>
						<a
							href="/news/"
						>
								All Press Releases 
						</a>
						<div v-if="$root.investor&&$root.investor.dfAuth==true&&$root.investor.loggedIn">
							<v-btn
								outlined
								v-on:click='newBlog($event)'
							>
								New Press Releases
							</v-btn>
							<br>
						</div>
					</div>
					<div v-if="isBlogger">
						<a
							v-if="isBlogger"
							href="/blogs/"
						>
								All Blogs
						</a>
						<div v-if="$root.investor&&$root.investor.dfAuth==true&&$root.investor.loggedIn">
							<v-btn
								outlined
								v-on:click='newBlog($event)'
							>
								New Blog
							</v-btn>
							<br>
						</div>
					</div>

					<div class=main_cont>
						<v-card
							color="grey lighten-2"
							class="mx-auto v_stack"
							max-width="600px"
							v-if="isBlogger || isReporter || isUpdater"
						>
							<br>
							<v-img
								class="mx-auto profile_img rounded"
								alt="Blogger"
								:src="profile.ProfilePic"
								transition="scale-transition"
							/>
							<v-card-title class="justify-center">
								{{ profile.FirstName }}	 {{ profile.LastName }}
							</v-card-title>
							<v-card-subtitle style="text-align: center;">
								{{ profile.JobTitle }}	
							</v-card-subtitle>
							<v-card-text>
								{{ profile.About }}
							</v-card-text>
						</v-card>
						<br>
						<br>
						<v-card
							class="mx-auto v_stack"
							max-width="600px"
							v-for="blog in blogs.published"
							:key="blog.id"
						>	
							<v-img
								class="mx-auto primary_img rounded"
								alt="Blog Header"
								:src="blog.image"
								transition="scale-transition"
							/>
							<v-card-title>
								{{ blog.title }}	
							</v-card-title>
							<v-card-subtitle>
								By 
								<a 
									v-if="isInvestor||isUpdater"
									:href="'/updater/'+blog.creator_id+'/'" 
								>
									{{ blog.first_name }} {{ blog.last_name}} 
								</a>
								<a 
									v-if="isNews||isReporter"
									:href="'/reporter/'+blog.creator_id+'/'" 
								>
									{{ blog.first_name }} {{ blog.last_name}} 
								</a>
								<a 
									v-if="isBlog||isBlogger"
									:href="'/blogger/'+blog.creator_id+'/'" 
								>
									{{ blog.first_name }} {{ blog.last_name}} 
								</a>
								on {{ blog.readableTimestamp }}
							</v-card-subtitle>
							<v-card-text>
								{{ blog.blurb }}
							</v-card-text>
							<v-card-actions>
								<v-btn
									v-if="isUpdater||isInvestor"
									outlined
									v-on:click="go('/investor/'+blog.blog_id+'/')"
								>
									View
								</v-btn>
								<v-btn
									v-if="isBlogger||isBlog"
									outlined
									v-on:click="go('/blog/'+blog.blog_id+'/')"
								>
									View
								</v-btn>
								<v-btn
									v-if="isReporter||isNews"
									outlined
									v-on:click="go('/article/'+blog.blog_id+'/')"
								>
									View
								</v-btn>
								<v-btn
									v-if="$root.investor.dfAuth==true&&($root.investor.isMA==true || $root.investor.uid==blog.creator_id)"
									outlined
									v-on:click="go('/edit/'+blog.blog_id+'/')"
								>
									Edit
								</v-btn>
							</v-card-actions>
						</v-card>

						<div v-if="this.$root.investor.uid==this.blogger_id">
							<div v-if="isReporter && blogs.unpublished.length>0">
								<center><h3>Unpublished Press Releases</h3></center>
							</div>
							<div v-if="isBlogger && blogs.unpublished.length>0">
								<center><h3>Unpublished Blogs</h3></center>
							</div>
							<div v-if="isUpdater && blogs.unpublished.length>0">
								<center><h3>Unpublished Investor Updates</h3></center>
							</div>
							<v-card
								class="mx-auto v_stack"
								max-width="600px"
								v-for="blog in blogs.unpublished"
								:key="blog.id"
							>	
								<v-img
									class="mx-auto primary_img rounded"
									alt="Blog Header"
									:src="blog.image"
									transition="scale-transition"
								/>
								<v-card-title>
									{{ blog.title }}	
								</v-card-title>
								<v-card-subtitle>
									{{ blog.readableTimestamp }}
								</v-card-subtitle>
								<v-card-text>
									{{ blog.blurb }}
								</v-card-text>
								<v-card-actions>
									<v-btn
										outlined
										v-on:click="go('/blog/'+blog.blog_id+'/')"
									>
										View
									</v-btn>
									<v-btn
										v-if="$root.investor.dfAuth==true&&($root.investor.isMA==true || $root.investor.uid==blog.creator_id)"
										outlined
										v-on:click="go('/edit/'+blog.blog_id+'/')"
									>
										Edit
									</v-btn>
									<v-btn
										v-if="$root.investor.uid==blogger_id"
										outlined
										v-on:click="deleteBlog(blog.blog_id, blog.blog_index)"
									>
										Delete	
									</v-btn>
								</v-card-actions>
							</v-card>
						</div>
					</div>
				</div>
		</div>
</template>

<script>
module.exports = {
	data() {
		return {
			isBlog: false,
			isBlogger: false,
			isReporter: false,
			isNews: false,
			isInvestor: false,
			isUpdater: false,
			blogs: {
				published: [],
				unpublished: []
			},
			profile: {
			},
			blogger_id: -1,
			titleFontStyle: {},
			titleFontStyleMobile: {},
			textFontStyle: {},
			textFontStyleMobile: {},
		};
	},
	methods: {
		deleteBlog(blog_id, blog_index) {
			// only allow deletes from unpublished set
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;

			this.$http.delete("/svc/blog/"+blog_id+"/", {headers}).then(response => {
				if(response.data.code==200) {
					this.init();
					this.$forceUpdate(); 
				} else {
					alert("Unable to delete:  " + response.data.description);
				}
			});
		},
		newBlog() {
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			try {
				headers['USERNAME'] = this.$root.investor.username;
				headers['PASSWORD'] = this.$root.investor.password;
				headers['SESSION'] = this.$root.investor.sessionKey;

				var ep = "/svc/blog/"

				if(this.isReporter) {
					ep = "/svc/news/"
				} else if(this.isInvestor) {
					ep = "/svc/investor/"
				}

				this.$http.put(ep, {}, {headers}).then(response => {
					if(response.data.code==200) {
						var e = '/edit/'+response.data.blog_id+'/'; 
						this.go(e)
					} else {
						alert("Unable to save:  " + response.data.description);
					}
				})
			} catch(e) {
				console.log(e);
			}
		},

		go(href) {
			console.log("going to " + href)
			window.location.href=href
			//this.$router.push({name:href})
		},

		getReadableDate(ts) {
			console.log(ts);
			var t = new Date(ts*1000);
			var options = { year: 'numeric', month: 'long', day: 'numeric' };
			var times = t.toLocaleDateString("en-US", options);
			console.log(times);
			return(times);
		},
		init() {
			console.log("Logged in: " + JSON.stringify(this.$root.$data));
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;

			var endpoint = this.$route.path
			if(endpoint.slice(-1)!="/") {
				endpoint+="/"
			}

			this.$http.get("/svc"+endpoint, {headers}).then(response => {
				this.profile = response.data.profile;
				this.blogger_id = response.data.blogger_id;
				this.titleFontStyle = response.data.global.title_font_style;
				this.titleFontStyleMobile = response.data.global.title_font_style_mobile;
				this.textFontStyle = response.data.global.text_font_style;
				this.textFontStyleMobile = response.data.global.text_font_style_mobile;

				this.blogs.published = [];
				this.blogs.unpublished = [];

				for(var blog in response.data.blog) {
					response.data.blog[blog].index = blog;
					response.data.blog[blog]["readableTimestamp"] = this.getReadableDate(response.data.blog[blog].ts);
					if(response.data.blog[blog].published==true) {
						this.blogs.published.push(response.data.blog[blog]);
					} else {
						this.blogs.unpublished.push(response.data.blog[blog]);
					}
				}
			})
		}
	},
	mounted() {
		if(this.$route.path.includes("blogger")) {
			this.isBlogger=true;
		} else if (this.$route.path.includes("blog")) {
			this.isBlog=true;
		} else if (this.$route.path.includes("reporter")) {
			this.isReporter=true
		} else if (this.$route.path.includes("news")) {
			this.isNews=true;
		} else if (this.$route.path.includes("investor")) {
			this.isInvestor=true;
		} else if (this.$route.path.includes("updater")) {
			this.isUpdater=true;
		}
		this.init();
	}

};
</script>

<style scoped>
.blog_cont {
	max-width: 1200px;
	height: 100%;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
}

.full {
}

.left_cont {
	display: inline;
	width: 20%;
	float: left;
}
.right_cont {
	display: inline;
	width: 20%;
	float: left;
}
.main_cont {
	display: inline;
	width: 60%;
}

.profile_img {
	max-height: 150px;
	max-width: 150px;
	object-fit: cover;
	margin: 10px 10px 10px 10px;
}

.primary_img {
	max-height: 250px;
	max-width: 600px;
	object-fit: cover;
}

.v_stack {
	margin: 10px 10px 10px 10px;
}
</style>


<style>
blockquote {
	padding: 0 1em;
	border-left: 3px solid rgba(0,0,0,.05);
	margin: 0 0 1.41575em 3em;
	font-style: italic;
}
hr {
	width:80%;
	text-align:left;
	margin-left:0;
	color: rgba(0,0,0,0.05);
}
ol, ul {
	margin: 0 0 1.41575em 3em;
	padding: 0;
}
</style>
